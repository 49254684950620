<template>
    <b-form @submit.prevent="onSubmit">
        <div class="row">
            <div class="col-12 col-md-4 col-lg-3">
                <form-input-select
                    id="positionsFilterForm.agent"
                    :label="$t('positionsFilterForm.agent')"
                    :options="filterOptions.agents"
                    v-model="filterAgent"
                    size="sm"
                />
            </div>
            <div class="col-12 col-md-4 col-lg-3">
                <form-input-select
                    id="positionsFilterForm.client"
                    :label="$t('positionsFilterForm.client')"
                    :options="filterOptions.clients"
                    v-model="filterClient"
                    size="sm"
                />
            </div>
            <div class="col-12 col-md-4 col-lg-3">
                <form-input-select
                    id="positionsFilterForm.stock"
                    :label="$t('positionsFilterForm.stock')"
                    :options="filterOptions.stocks"
                    v-model="filterISIN"
                    size="sm"
                />
            </div>
        </div>

        <div class="row" v-if="false">
            <div class="col-12 col-md-4 col-lg-3">
                <form-input-date-picker
                    v-if="limitFromActive"
                    id="positionsFilterForm.limitFrom"
                    :label="$t('positionsFilterForm.limitFrom')"
                    v-model="limitFrom"
                    size="sm"
                    :locale="locale"
                />
                <b-form-checkbox
                    v-model="limitFromActive"
                >
                    {{$t('positionsFilterForm.limitFromActive')}}
                </b-form-checkbox>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
                <form-input-date-picker
                    v-if="limitToActive"
                    id="positionsFilterForm.limitTo"
                    :label="$t('positionsFilterForm.limitTo')"
                    v-model="limitTo"
                    size="sm"
                    :locale="locale"
                />
                <b-form-checkbox
                    v-model="limitToActive"
                >
                    {{$t('positionsFilterForm.limitToActive')}}
                </b-form-checkbox>
            </div>
        </div>

        <div class="mt-4">
            <b-btn
                variant="primary"
                class="mr-2"
                size="sm"
                type="submit"
            >
                <span class="iconify" data-icon="mdi:funnel"></span> {{$t('positionsFilterForm.submit')}}
            </b-btn>
            <b-btn
                variant="secondary"
                size="sm"
                @click.prevent="onReset"
            >
                <span class="iconify" data-icon="mdi:close"></span> {{$t('positionsFilterForm.reset')}}
            </b-btn>
        </div>
    </b-form>
</template>

<script>
export default {
    name: 'PositionsFilterForm',
    props: {
        client: {
            type: String,
            required: false,
            default: null
        },
        agent: {
            type: String,
            required: false,
            default: null
        },
        stock: {
            type: String,
            required: false,
            default: null
        },
        filterOptions: {
            type: Object,
            required: false,
            default () {
                return {
                    clients: [],
                    stocks: [],
                    agents: []
                }
            }
        },
        shownDataset: {
            type: Array,
            required: false,
            default: () => []
        },
        emptyFilter: {
            type: Object,
            required: false,
            default: () => ({ value: null, text: 'No filter' })
        },
        to: {
            type: String,
            required: false,
            default: null
        },
        from: {
            type: String,
            required: false,
            default: null
        }
    },
    data () {
        return {
            filterClient: (this.client) ? `${this.client}` : null,
            filterISIN: (this.stock) ? `${this.stock}` : null,
            filterAgent: (this.agent) ? `${this.agent}` : null,
            limitFrom: (this.from) ? `${this.from}` : null,
            limitTo: (this.to) ? `${this.to}` : null,
            limitToActive: !!this.to,
            limitFromActive: !!this.from
        }
    },
    computed: {
        locale () {
            return (this.$store.state.lang === 'cz') ? 'cs-CZ' : 'en-US'
        },
        filterData () {
            return JSON.stringify({
                filterClient: this.filterClient,
                filterISIN: this.filterISIN,
                filterAgent: this.filterAgent
            })
        }
    },
    watch: {
        filterData () {
            this.$nextTick(() => {
                this.$emit('filters', {
                    filterClient: this.filterClient,
                    filterISIN: this.filterISIN,
                    filterAgent: this.filterAgent
                })
            })
        },
        client (newValue) {
            this.$nextTick(() => {
                this.filterClient = newValue
            })
        },
        agent (newValue) {
            this.$nextTick(() => {
                this.filterAgent = newValue
            })
        },
        stock (newValue) {
            this.$nextTick(() => {
                this.filterISIN = newValue
            })
        },
        to (newValue) {
            this.$nextTick(() => {
                this.limitTo = newValue
            })
        },
        from (newValue) {
            this.$nextTick(() => {
                this.limitFrom = newValue
            })
        }
    },
    methods: {
        onSubmit () {
            this.$emit('submit', JSON.parse(JSON.stringify({
                filterClient: this.filterClient,
                filterISIN: this.filterISIN,
                filterAgent: this.filterAgent,
                limitFrom: (this.limitFromActive && this.limitFrom) ? this.limitFrom : undefined,
                limitTo: (this.limitToActive && this.limitTo) ? this.limitTo : undefined
            })))
        },
        onReset () {
            this.filterClient = null
            this.filterISIN = null
            this.filterAgent = null
            this.limitFrom = null
            this.limitTo = null
            this.limitFromActive = false
            this.limitToActive = false
            this.$emit('reset')
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "positionsFilterForm": {
            "submit": "Filtrovat",
            "reset": "Reset",
            "agent": "Agent",
            "client": "Klient",
            "stock": "Cenný papír",
            "limitFrom": "Datum realizace od",
            "limitFromActive": "Omezit datum realizace od",
            "limitTo": "Datum realizace do",
            "limitToActive": "Omezit datum realizace do"
        }
    }
}
</i18n>
