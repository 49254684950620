<template>
    <div class="p-4 bg-white shadow rounded-sm">
        <slot></slot>
        <data-table
            class="table-sm-font"
            :auto-update="false"
            :header="header"
            :loading="loading"
            :data="dataset"
            :buttons="buttons"
            :lang="currentLang"
            :paging="true"
            :responsive="'lg'"
            :actions="false"
            :exportable="true"
            @export="onExport"
        />
    </div>
</template>

<script>
import XLSX from 'xlsx'
import download from 'downloadjs'

const numberFormatter = new Intl.NumberFormat('cs-CZ')
const formatCurrency = (value) => {
    if (typeof value === 'number') {
        return numberFormatter.format(Math.round(value * 100) / 100)
    }
    return 0
}

export default {
    name: 'PositionsDataTable',
    props: {
        dataset: {
            type: Array,
            required: false,
            default: () => []
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        amountSum () {
            return formatCurrency(this.dataset.reduce((sum, item) => sum + ((item.amount) ? parseInt(item.amount) : 0), 0))
        },
        volumeSum () {
            return formatCurrency(this.dataset.reduce((sum, item) => sum + ((item.volume) ? parseInt(item.volume) : 0), 0))
        },
        currentLang () {
            return (this.$store.state.lang === 'cz') ? 'cs_CZ' : 'en_US'
        },
        header () {
            return [
                {
                    text: 'Capitol ID',
                    data: 'capitolId',
                    sortable: true,
                    filterable: true,
                    format: (value) => {
                        return value || this.$t('positionsDataTable.noCapitolId')
                    }
                },
                {
                    text: 'Jméno klienta',
                    data: 'name',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Agent',
                    data: 'externalBrokerAgentName',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Ref. kód',
                    data: 'externalBrokerAgentReferenceCode',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'ISIN',
                    data: 'ISIN',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Název CP',
                    data: 'stockName',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Počet',
                    data: 'amount',
                    sortable: true,
                    filterable: true,
                    format: formatCurrency,
                    aggregate (sum, item, index, rows) {
                        sum += ((item.amount) ? parseInt(item.amount) : 0)
                        if (index === rows.length - 1) {
                            return formatCurrency(sum)
                        }
                        return sum
                    },
                    aggregateInitialValue: 0,
                    aggregateText: this.$t('positionsDataTable.total')
                },
                {
                    text: 'Objem v měně',
                    data: 'volume',
                    sortable: true,
                    filterable: true,
                    format: formatCurrency
                },
                {
                    text: 'Objem v CZK',
                    data: 'volumeInCzk',
                    sortable: true,
                    filterable: true,
                    format: formatCurrency,
                    sortFn (a, b) {
                        return ((a === b) ? 0 : ((a > b) ? 1 : -1))
                    },
                    aggregate (sum, item, index, rows) {
                        sum += ((item.volumeInCzk) ? parseInt(item.volumeInCzk) : 0)
                        if (index === rows.length - 1) {
                            return formatCurrency(sum)
                        }
                        return sum
                    },
                    aggregateInitialValue: 0,
                    aggregateText: this.$t('positionsDataTable.total')
                },
                {
                    text: 'Datum aktualizace',
                    data: 'refreshedAt',
                    sortable: true,
                    filterable: true,
                    format: (value) => {
                        return new Date(value).toLocaleDateString()
                    },
                    sortFn (a, b) {
                        a = (new Date(a)).valueOf()
                        b = (new Date(b)).valueOf()
                        return ((a === b) ? 0 : ((a > b) ? 1 : -1))
                    }
                },
                {
                    text: 'Měna',
                    data: 'currencySymbol',
                    sortable: true,
                    filterable: true
                }
            ]
        },
        buttons () {
            return [

            ]
        }
    },
    methods: {
        onExport (data) {
            const wk = XLSX.utils.book_new()
            const [jsonHeader, ...rows] = data
            const formatAsNumber = (value) => {
                // eslint-disable-next-line no-useless-escape
                return Number(`${value}`.replace(/[^0-9,\.]/g, '').replace(',', '.') ?? 0)
            }
            const sheet = XLSX.utils.json_to_sheet([jsonHeader, ...rows.map(row => {
                if (row.amount !== undefined) {
                    row.amount = formatAsNumber(row.amount)
                }
                if (row.volume !== undefined) {
                    row.volume = formatAsNumber(row.volume)
                }
                if (row.volumeInCzk !== undefined) {
                    row.volumeInCzk = formatAsNumber(row.volumeInCzk)
                }
                return row
            })], { skipHeader: true })
            XLSX.utils.book_append_sheet(wk, sheet, 'export')
            const output = XLSX.write(wk, { bookType: 'xlsx', bookSST: false, type: 'array' })
            download(
                new Blob([output], { type: 'application/octet-stream' }),
                'pozice.xlsx',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            )
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "positionsDataTable": {
            "positions": "Pozice",
            "noCapitolId": "Neevidováno",
            "total": "Celkem: "
        }
    },
    "en": {
        "propertyDataTable": {
            "positions": "Positions",
            "noCapitolId": "Not in evidence",
            "total": "Total: "
        }
    }
}
</i18n>
