<template>
    <div>
        <div class="container">
            <h1>{{$t('positions.heading')}}</h1>
            <p>{{$t('positions.subtitle')}}</p>
        </div>
        <div class="container-fluid">
            <positions-data-table
                :dataset="positions"
                :loading="positionsLoading"
            >
                <loader-overlay
                    :loading="filtersLoading"
                    class="mb-5"
                >
                    <positions-filter-form
                        :filter-options="filterOptions"
                        :client="client"
                        :agent="agent"
                        :stock="stock"
                        :from="from"
                        :to="to"
                        @reset="onReset"
                        @submit="onFilter"
                        @filters="onLoadFilters"
                    />
                </loader-overlay>
            </positions-data-table>
        </div>
    </div>
</template>

<script>
import LoaderOverlay from '../../../../../../Components/LoaderOverlay.vue'
import PositionsDataTable from './Components/PositionsDataTable.vue'
import PositionsFilterForm from './Components/PositionsFilterForm.vue'

export default {
    name: 'Positions',
    components: {
        PositionsDataTable,
        PositionsFilterForm,
        LoaderOverlay
    },
    props: {
        client: {
            type: String,
            required: false,
            default: null
        },
        agent: {
            type: String,
            required: false,
            default: null
        },
        stock: {
            type: String,
            required: false,
            default: null
        },
        from: {
            type: String,
            required: false,
            default: null
        },
        to: {
            type: String,
            required: false,
            default: null
        }
    },
    data () {
        return {
            filterOptions: {
                clients: [],
                stocks: [],
                agents: []
            },
            positionsLoading: true,
            positions: [],
            nextTickTrigger: false,
            filtersLoading: true,
            tempFilters: {
                filterClient: this.$route.query.client || undefined,
                filterISIN: this.$route.query.stock || undefined,
                filterAgent: this.$route.query.agent || undefined,
                limitFrom: this.$route.query.from || undefined,
                limitTo: this.$route.query.to || undefined
            }
        }
    },
    computed: {
        emptyFilter () {
            return { value: null, text: this.$t('positions.doNotApplyFilter') }
        }
    },
    watch: {
        $route () {
            this.$nextTick(() => {
                this.loadPositions()
                this.tempFilters = {
                    filterClient: this.$route.query.client || undefined,
                    filterISIN: this.$route.query.stock || undefined,
                    filterAgent: this.$route.query.agent || undefined,
                    limitFrom: this.$route.query.from || undefined,
                    limitTo: this.$route.query.to || undefined
                }
                this.loadPositionsFilters()
            })
        }
    },
    mounted () {
        this.loadPositions()
        this.loadPositionsFilters()
    },
    methods: {
        onFilter (filterOptions) {
            this.$router.push({
                name: 'ClientsPositions',
                query: {
                    client: filterOptions.filterClient || undefined,
                    agent: filterOptions.filterAgent || undefined,
                    stock: filterOptions.filterISIN || undefined,
                    from: filterOptions.limitFrom || undefined,
                    to: filterOptions.limitTo || undefined
                }
            }).catch(() => {})
        },
        onReset () {
            this.$router.push({ name: 'ClientsPositions' }).catch(() => {})
        },
        onLoadFilters (tempFilters) {
            this.tempFilters = tempFilters
            this.$nextTick(() => {
                this.loadPositionsFilters()
            })
        },
        loadPositions () {
            this.nextTickTrigger = false
            this.positionsLoading = true
            this.positions = []
            this.$api.capitol.getBrokerPositions(
                this.$user.izdocsCompanyId,
                {
                    filterClient: this.$route.query.client || undefined,
                    filterISIN: this.$route.query.stock || undefined,
                    filterAgent: this.$route.query.agent || undefined,
                    limitFrom: this.$route.query.from || undefined,
                    limitTo: this.$route.query.to || undefined
                }
            ).then((response) => {
                this.positions = [...response.data]
            }).catch((error) => {
                console.error(error)
                this.$notify.error(this.$t('positions.positionsLoadError'))
            }).finally(() => {
                this.$nextTick(() => {
                    this.positionsLoading = false
                })
            })
        },
        loadPositionsFilters () {
            this.filtersLoading = true
            this.$api.capitol.getBrokerPositionsFilters(
                this.$user.izdocsCompanyId,
                this.tempFilters
            ).then((response) => {
                this.filterOptions.clients = [this.emptyFilter, ...response.data.clients]
                this.filterOptions.stocks = [this.emptyFilter, ...response.data.stocks]
                this.filterOptions.agents = [this.emptyFilter, ...response.data.agents]
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                this.$nextTick(() => {
                    this.filtersLoading = false
                })
            })
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "positions": {
            "heading": "Seznam pozic klientů zprostředkovatele",
            "subtitle": "Seznam všech evidovaných pozic klientů zprostředkovatele.",
            "positionsLoadError": "Nepodařilo se načíst seznam pozic.",
            "doNotApplyFilter": "Neaplikovat filtr"
        }
    },
    "en": {
    }
}
</i18n>
